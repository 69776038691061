<template>
  <div class="game-item">
    <NuxtLink :to="game.url" class="item" :class="{ 'hover-shine': !$device.isMobileOrTablet }">
      <BaseImg class="img-game" :src="game.objectImgMb" />
    </NuxtLink>
    <div v-if="sumJackpot[game.jackpot] > 0" class="jackpot">
      <AnimateNumber :number="sumJackpot[game.jackpot]" />
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import AnimateNumber from '~/components/common/animate-number-rolling.vue'

const { $pinia, $device } = useNuxtApp()
const store = useAppStore($pinia)
const { sumJackpot } = storeToRefs(store)
const router = useRouter()

const props = defineProps({
  game: {
    type: Object,
    default: () => {}
  },
  index: {
    type: Number,
    default: 0
  }
})
</script>
<style lang="scss" scoped src="~/assets/scss/components/mobile/pages/home/game-center/item.scss"></style>
